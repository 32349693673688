import { Euler } from 'three';
import { Vector3 } from 'three';

import DataStorage from 'src/apis/data/DataStorage';

class MediaRepository {
  constructor() {
    this.medias = [];
    this.refreshAt;
    this.vec3 = new Vector3();
    this.euler = new Euler();
  }

  _convertToMediaData = (mediasData) => {
    const temp = mediasData.map((item, index) => {
      let position = this.vec3.clone();
      let rotation = this.euler.clone();

      const mediaProps = {
        id: item.id,
        mediaType: item.mediaType,
        rotation: rotation.set(item.rotation.x, item.rotation.y, item.rotation.z, 'XYZ'),
        position: position.set(item.position.x, item.position.y, item.position.z),
        source: item.source,
        scale: item.scale,
        color: item.color,
      };
      return mediaProps;
    });

    return temp;
  };

  reloadMediasIfNeeded = () => {
    if (this.refreshAt != DataStorage.refreshAt) {
      // console.log('reloadMediasIfNeeded');
      this.medias = this._convertToMediaData(DataStorage.medias);
      this.refreshAt = DataStorage.refreshAt;
    }
  };

  getMedias = () => {
    this.reloadMediasIfNeeded();
    return this.medias;
  };
}

export default new MediaRepository();
