const store = {
  id: 'factorie',
  name: 'Factorie',
  model:
    'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/factorie/store-models/factorie-D.glb',
  modelId: '50190b5811a44d7b98a67caa83d45528',
  storeFrontId: 'a7af6068f1744712bc151ce4be109727',
  storeLogo:
    'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/factorie/factorie-logo.svg',
  storeWelcomeText: 'Welcome to the Factorie Experience.',
  cameraRotation: { azimuthal: -4, polar: 90 },
  rotationDegrees: -86,
  navigationMenu: [
    {
      title: 'Entrance',
      locationId: 'a7af6068f1744712bc151ce4be109727',
      options: {
        rotation: { azimuthal: -0.06981317007977318, polar: 1.5707963267948966 },
      },
    },
  ],
  panoImageBaseUrl:
    'https://fidelitystorageprod-cdn.azureedge.net/cottonon-vr-store-3d/factorie/location-images-equi/',
  panoImageFormat: '.webp',
  tiledImageSettings: {
    baseUrl512:
      'https://fidelitystorageprod-cdn.azureedge.net/cottonon-vr-store-3d/factorie/ocation-images-cube-512/',
    baseUrl4k:
      'https://fidelitystorageprod-cdn.azureedge.net/cottonon-vr-store-3d/factorie/location-images-tiled-4k/',
    baseUrl2k:
      'https://fidelitystorageprod-cdn.azureedge.net/cottonon-vr-store-3d/factorie/location-images-tiled-2k/',
  },
};

export default store;
