class DataStorage {
  constructor() {
    this.storeId = '';
    this.store;
    this.locations;
    this.products;
    this.tagGroups;
    this.tags;
    this.medias;
    this.refreshAt;
  }

  initialize = async (storeId) => {
    if (this.storeId != storeId) {
      const storeData = await require(`./${storeId}/store.js`);
      const locationsData = await require(`./${storeId}/locations.js`);
      const productsData = await require(`./${storeId}/products.js`);
      const tagGroupsData = await require(`./${storeId}/tag-groups.js`);
      const tagsData = await require(`./${storeId}/tags.js`);
      const mediasData = await require(`./${storeId}/medias.js`);

      this.store = storeData.default;
      this.locations = locationsData.default;
      this.products = productsData.default;
      this.tagGroups = tagGroupsData.default;
      this.tags = tagsData.default;
      this.medias = mediasData.default;

      this.storeId = storeId;
      this.refreshAt = new Date();
      console.log(
        'initialize data storage',
        storeId
        // this.store,
        // this.locations,
        // this.products,
        // this.tagGroups,
        // this.tags,
        // this.medias
      );
    }
  };
}

export default new DataStorage();
