const locations = [
  {
    sid: '2aab40154699406ab2f60927554d3d82',
    id: '2aab40154699406ab2f60927554d3d82',
    position: {
      x: -0.03139517456293106,
      y: 1.5505021810531616,
      z: 0.4057917594909668,
    },
    rotation: {
      x: 0.3448667982032928,
      y: -16.319255694138803,
      z: -0.22793467806459533,
    },
  },
  {
    sid: 'b824a7aeab1344a69c71c8a37839382b',
    id: 'b824a7aeab1344a69c71c8a37839382b',
    position: {
      x: 1.2028634548187256,
      y: 1.5481828451156616,
      z: 0.3774322271347046,
    },
    rotation: {
      x: 1.0016911271947688,
      y: 35.69281135751725,
      z: -0.5687547662503425,
    },
  },
  {
    sid: '4ae1c2a958c04a7488906adacdafe2f7',
    id: '4ae1c2a958c04a7488906adacdafe2f7',
    position: {
      x: -0.03728848323225975,
      y: 1.551336646080017,
      z: -0.881463885307312,
    },
    rotation: {
      x: 0.17605613469223882,
      y: 81.6220859237827,
      z: -0.10781029285681998,
    },
  },
  {
    sid: '26396b70f9a94d0083d5cab1c7a2c7e9',
    id: '26396b70f9a94d0083d5cab1c7a2c7e9',
    position: {
      x: 1.2816736698150635,
      y: 1.542608618736267,
      z: -0.8487070202827454,
    },
    rotation: {
      x: -0.48581263717079165,
      y: -49.72083338834502,
      z: -0.8543882522903347,
    },
  },
  {
    sid: '438afa8cf5ee4cf49c1a8f5e92287649',
    id: '438afa8cf5ee4cf49c1a8f5e92287649',
    position: {
      x: -1.008606195449829,
      y: 1.5670565366744995,
      z: 0.3724120855331421,
    },
    rotation: {
      x: 0.19687731225752927,
      y: -88.17363931368192,
      z: 0.17393543919071683,
    },
  },
  {
    sid: 'ec26515a183549cebc79391185eaa12c',
    id: 'ec26515a183549cebc79391185eaa12c',
    position: {
      x: -1.0154584646224976,
      y: 1.5624194145202637,
      z: -1.4798020124435425,
    },
    rotation: {
      x: 0.25130913610833916,
      y: -85.11020437690529,
      z: 0.2896168630031167,
    },
  },
  {
    sid: 'e532b96fff1a4f91ab2227c08f333685',
    id: 'e532b96fff1a4f91ab2227c08f333685',
    position: {
      x: -0.016124822199344635,
      y: 1.5588421821594238,
      z: -2.0558619499206543,
    },
    rotation: {
      x: 0.5263066456866223,
      y: -87.53251525517919,
      z: 0.3010426613203567,
    },
  },
  {
    sid: '2995419d4d6b4cf9ba3d63407a96be42',
    id: '2995419d4d6b4cf9ba3d63407a96be42',
    position: {
      x: 1.124314785003662,
      y: 1.5594818592071533,
      z: -2.0127036571502686,
    },
    rotation: {
      x: 0.35501283123993,
      y: -140.00578827881466,
      z: 0.12468920558307506,
    },
  },
  {
    sid: 'e6065f2104c54808ac48ffcd5222dfbd',
    id: 'e6065f2104c54808ac48ffcd5222dfbd',
    position: {
      x: -0.09818753600120544,
      y: 1.5550329685211182,
      z: -3.3403708934783936,
    },
    rotation: {
      x: 0.4028469420182832,
      y: 87.24380490206603,
      z: -0.11318047363967425,
    },
  },
  {
    sid: 'c8df1d817120462d8d669b5634d29668',
    id: 'c8df1d817120462d8d669b5634d29668',
    position: {
      x: 1.1127609014511108,
      y: 1.5570545196533203,
      z: -3.3485496044158936,
    },
    rotation: {
      x: 0.6292621845972853,
      y: 83.18794463878896,
      z: -0.10692319755082215,
    },
  },
  {
    sid: 'dee45e7a128440e9b529e95e4dd26bc0',
    id: 'dee45e7a128440e9b529e95e4dd26bc0',
    position: {
      x: -0.11361005902290344,
      y: 1.5551788806915283,
      z: -4.816576957702637,
    },
    rotation: {
      x: 0.01719714951158261,
      y: -61.67461282008466,
      z: 0.5957619374552636,
    },
  },
  {
    sid: '0b0f1f8f16404657ae6001b33a7eb792',
    id: '0b0f1f8f16404657ae6001b33a7eb792',
    position: {
      x: 1.3626108169555664,
      y: 1.5497156381607056,
      z: -4.873555660247803,
    },
    rotation: {
      x: 0.3335250959869938,
      y: -134.24444043534507,
      z: 1.0712561161951648,
    },
  },
  {
    sid: 'a81922673e774e538b36f56500b1a6fe',
    id: 'a81922673e774e538b36f56500b1a6fe',
    position: {
      x: -0.32454976439476013,
      y: 1.5476243495941162,
      z: -5.672281742095947,
    },
    rotation: {
      x: 0.3827564585982604,
      y: 139.30693505015876,
      z: -0.02071966827408212,
    },
  },
  {
    sid: 'da522052b77e40648c317909ba655cda',
    id: 'da522052b77e40648c317909ba655cda',
    position: {
      x: -1.1931712627410889,
      y: 1.5452611446380615,
      z: -6.223501682281494,
    },
    rotation: {
      x: 0.14732008847632388,
      y: -119.9767212500801,
      z: -0.5390888613303566,
    },
  },
  {
    sid: '28866a8e6f794502a727e858c084e682',
    id: '28866a8e6f794502a727e858c084e682',
    position: {
      x: -2.5928468704223633,
      y: 1.542201280593872,
      z: -6.271512508392334,
    },
    rotation: {
      x: -0.07396450978754406,
      y: -145.13498540487055,
      z: -0.7549362159699409,
    },
  },
  {
    sid: 'd265351c355843a88a27d07ca1a5c95e',
    id: 'd265351c355843a88a27d07ca1a5c95e',
    position: {
      x: -2.4813120365142822,
      y: 1.5523955821990967,
      z: -7.2439961433410645,
    },
    rotation: {
      x: 0.08412224530699748,
      y: -147.79553979351795,
      z: -0.5405211954376751,
    },
  },
  {
    sid: 'f972652206d048e1babcbcd60df47868',
    id: 'f972652206d048e1babcbcd60df47868',
    position: {
      x: -2.8307180404663086,
      y: 1.5534086227416992,
      z: -8.585205078125,
    },
    rotation: {
      x: 1.1211079981055185,
      y: -157.0954386934448,
      z: -0.8126566053884543,
    },
  },
  {
    sid: 'eba19a02b7904f3fbbd88c6aa0e282eb',
    id: 'eba19a02b7904f3fbbd88c6aa0e282eb',
    position: {
      x: -2.679543972015381,
      y: 1.549712896347046,
      z: -10.023435592651367,
    },
    rotation: {
      x: 0.6945118531212902,
      y: -151.47240210699115,
      z: -0.47737712185552583,
    },
  },
  {
    sid: 'b2e3ac91e309498395137ae091040bd5',
    id: 'b2e3ac91e309498395137ae091040bd5',
    position: {
      x: -2.6724841594696045,
      y: 1.5445148944854736,
      z: -11.265419006347656,
    },
    rotation: {
      x: 0.9627555645118165,
      y: -139.72273293398806,
      z: 0.4047391672130134,
    },
  },
  {
    sid: 'f83bc1b6f5c74fa98bbab280a30e562f',
    id: 'f83bc1b6f5c74fa98bbab280a30e562f',
    position: {
      x: -4.014518737792969,
      y: 1.53757905960083,
      z: -11.270698547363281,
    },
    rotation: {
      x: 0.5912174449361701,
      y: -114.72163153051999,
      z: -0.17874599959522525,
    },
  },
  {
    sid: '6dee2bddad7045c1b5eba4de817d744d',
    id: '6dee2bddad7045c1b5eba4de817d744d',
    position: {
      x: -5.437483310699463,
      y: 1.5336354970932007,
      z: -11.278542518615723,
    },
    rotation: {
      x: 0.3605678186441992,
      y: -98.97620458193246,
      z: -0.16103825961139942,
    },
  },
  {
    sid: 'ba30b11be0444e388143555a39bfad36',
    id: 'ba30b11be0444e388143555a39bfad36',
    position: {
      x: -5.336771488189697,
      y: 1.5407601594924927,
      z: -9.977339744567871,
    },
    rotation: {
      x: 0.22478144086983778,
      y: -74.23065476034488,
      z: -0.12821812109923802,
    },
  },
  {
    sid: '20ee315d7bbb4ac89283cd7ab491ae67',
    id: '20ee315d7bbb4ac89283cd7ab491ae67',
    position: {
      x: -5.5083394050598145,
      y: 1.5411667823791504,
      z: -9.076099395751953,
    },
    rotation: {
      x: 0.5100571624161508,
      y: -119.46663070167291,
      z: -0.31287475098678674,
    },
  },
  {
    sid: '7709134a62e9442aaaf71f1f99b2bc76',
    id: '7709134a62e9442aaaf71f1f99b2bc76',
    position: {
      x: -5.543925762176514,
      y: 1.5499532222747803,
      z: -7.254305839538574,
    },
    rotation: {
      x: 0.5815025587829263,
      y: -90.9096935634429,
      z: -0.47830399911375204,
    },
  },
  {
    sid: '1f684327ad774fd4879b857c63c9816d',
    id: '1f684327ad774fd4879b857c63c9816d',
    position: {
      x: -7.204991340637207,
      y: 1.5444629192352295,
      z: -7.512869834899902,
    },
    rotation: {
      x: 0.5685510149607358,
      y: -98.48864411597225,
      z: -0.44355735264892476,
    },
  },
  {
    sid: '4a784f802ddb463b8894cb6f70e9a940',
    id: '4a784f802ddb463b8894cb6f70e9a940',
    position: {
      x: -8.443256378173828,
      y: 1.5480108261108398,
      z: -7.376643657684326,
    },
    rotation: {
      x: 0.4396929329029704,
      y: -106.97448432250155,
      z: -0.4120195582865001,
    },
  },
  {
    sid: 'd88dac272f5e44a3b6c8826b0277a3c2',
    id: 'd88dac272f5e44a3b6c8826b0277a3c2',
    position: {
      x: -9.929966926574707,
      y: 1.5462619066238403,
      z: -7.351617813110352,
    },
    rotation: {
      x: 0.16977865424293614,
      y: -155.79103978148527,
      z: -0.4058788890701703,
    },
  },
  {
    sid: '4e149e36e1844e4ea0a3f35b02559452',
    id: '4e149e36e1844e4ea0a3f35b02559452',
    position: {
      x: -10.977460861206055,
      y: 1.548172950744629,
      z: -7.48728084564209,
    },
    rotation: {
      x: 0.4113455712708758,
      y: -124.89628876636556,
      z: -0.20108777655622936,
    },
  },
  {
    sid: 'a5529f603f764103b7104354cdf49cab',
    id: 'a5529f603f764103b7104354cdf49cab',
    position: {
      x: -11.00202751159668,
      y: 1.5494107007980347,
      z: -8.294293403625488,
    },
    rotation: {
      x: 0.20397060829291075,
      y: -137.31630988389145,
      z: 0.31573692645328383,
    },
  },
  {
    sid: 'f68eb883661b4dad8cf9ac19f02332de',
    id: 'f68eb883661b4dad8cf9ac19f02332de',
    position: {
      x: -10.267709732055664,
      y: 1.5496901273727417,
      z: -9.175406455993652,
    },
    rotation: {
      x: 0.18328576532412327,
      y: -108.0191751574514,
      z: 0.4615063176339016,
    },
  },
  {
    sid: 'a7d2f4fe18944145a63e3bc40ef175fb',
    id: 'a7d2f4fe18944145a63e3bc40ef175fb',
    position: {
      x: -9.330727577209473,
      y: 1.548180341720581,
      z: -9.222771644592285,
    },
    rotation: {
      x: 0.4427354936594495,
      y: -153.90188312658066,
      z: 0.6018895300421911,
    },
  },
  {
    sid: 'e85f35bfb161409bba16493826e265fd',
    id: 'e85f35bfb161409bba16493826e265fd',
    position: {
      x: -8.483067512512207,
      y: 1.548375129699707,
      z: -9.22704792022705,
    },
    rotation: {
      x: -0.03294431233361547,
      y: -138.13946537563794,
      z: 0.9098817731696094,
    },
  },
  {
    sid: 'eb89e8f341cb45ab8fc8c1796b962ef4',
    id: 'eb89e8f341cb45ab8fc8c1796b962ef4',
    position: {
      x: -8.267570495605469,
      y: 1.5438553094863892,
      z: -8.176548957824707,
    },
    rotation: {
      x: 0.15786670819713727,
      y: -107.24073390483045,
      z: 0.06851841479508886,
    },
  },
  {
    sid: '2573e7bfac854139953e0b7fa1b1cfac',
    id: '2573e7bfac854139953e0b7fa1b1cfac',
    position: {
      x: -7.65526008605957,
      y: 1.5448532104492188,
      z: -9.469313621520996,
    },
    rotation: {
      x: 0.2426765363678259,
      y: -146.74215590117564,
      z: 0.30481078228062036,
    },
  },
  {
    sid: 'f93df91325974034ba3f058bd8c6d1e5',
    id: 'f93df91325974034ba3f058bd8c6d1e5',
    position: {
      x: -6.483036994934082,
      y: 1.5396027565002441,
      z: -9.35698413848877,
    },
    rotation: {
      x: 0.2539846715418667,
      y: -166.0739387061216,
      z: 0.0918046419104681,
    },
  },
  {
    sid: 'fa2a4cda693f455aa0af0bf94b559754',
    id: 'fa2a4cda693f455aa0af0bf94b559754',
    position: {
      x: -6.900472164154053,
      y: 1.5435240268707275,
      z: -11.358119010925293,
    },
    rotation: {
      x: 0.14146732989013858,
      y: -158.62480659585063,
      z: 0.8175430541079012,
    },
  },
  {
    sid: '216d44228d8c4ccb9e9d546ef5381bb0',
    id: '216d44228d8c4ccb9e9d546ef5381bb0',
    position: {
      x: -7.740212917327881,
      y: 1.546879768371582,
      z: -11.394381523132324,
    },
    rotation: {
      x: 0.5401494815963743,
      y: -167.2166690840789,
      z: -0.28055252289072274,
    },
  },
  {
    sid: '3776ee097708449499345d369cbfeca7',
    id: '3776ee097708449499345d369cbfeca7',
    position: {
      x: -8.747349739074707,
      y: 1.549748420715332,
      z: -11.375572204589844,
    },
    rotation: {
      x: 0.38236356335319344,
      y: -161.8368434752242,
      z: 0.05091721981254709,
    },
  },
  {
    sid: '963f819fd5b3405a8b93574e692f393d',
    id: '963f819fd5b3405a8b93574e692f393d',
    position: {
      x: -10.253220558166504,
      y: 1.5561410188674927,
      z: -11.321869850158691,
    },
    rotation: {
      x: 0.259574716748292,
      y: -152.84461667757725,
      z: -0.2374842783596269,
    },
  },
  {
    sid: '37bceec8a234453aa017cb60b45d8b62',
    id: '37bceec8a234453aa017cb60b45d8b62',
    position: {
      x: -11.34185791015625,
      y: 1.5580878257751465,
      z: -11.206003189086914,
    },
    rotation: {
      x: 0.24667559097987835,
      y: -157.2152801638446,
      z: 0.2373440364850739,
    },
  },
  {
    sid: '639bb5e8d6fc48d38ba8ef7055eaa2db',
    id: '639bb5e8d6fc48d38ba8ef7055eaa2db',
    position: {
      x: -12.436138153076172,
      y: 1.5583844184875488,
      z: -11.205410957336426,
    },
    rotation: {
      x: 0.359506242585829,
      y: -156.68619280668554,
      z: 0.30422771567601564,
    },
  },
  {
    sid: '412c52705f5b4a549a93aa442ad383e6',
    id: '412c52705f5b4a549a93aa442ad383e6',
    position: {
      x: -13.729011535644531,
      y: 1.552245855331421,
      z: -10.890979766845703,
    },
    rotation: {
      x: 0.6257513650832333,
      y: -159.49082979981264,
      z: 0.03428934358444579,
    },
  },
  {
    sid: '8904bd10359d4610ba6880a84a234ec4',
    id: '8904bd10359d4610ba6880a84a234ec4',
    position: {
      x: -15.119400978088379,
      y: 1.541634202003479,
      z: -11.311012268066406,
    },
    rotation: {
      x: 0.5500320615952716,
      y: -141.59754950424568,
      z: -0.05401021010969346,
    },
  },
  {
    sid: 'e1fef0227b824b58ad548221a5d35451',
    id: 'e1fef0227b824b58ad548221a5d35451',
    position: {
      x: -16.369953155517578,
      y: 1.5418111085891724,
      z: -11.088512420654297,
    },
    rotation: {
      x: 0.2881700766476436,
      y: -137.44001122451454,
      z: 0.148040978676601,
    },
  },
  {
    sid: '004d1a251c984039976184b4231d5329',
    id: '004d1a251c984039976184b4231d5329',
    position: {
      x: -16.45478057861328,
      y: 1.544646978378296,
      z: -9.982165336608887,
    },
    rotation: {
      x: 0.24358034134003415,
      y: -133.83190952005688,
      z: 0.5964075090532158,
    },
  },
  {
    sid: '4be3a28de68b47c3afdcaa1430b47042',
    id: '4be3a28de68b47c3afdcaa1430b47042',
    position: {
      x: -16.407772064208984,
      y: 1.545778751373291,
      z: -8.863027572631836,
    },
    rotation: {
      x: 0.5202638522451842,
      y: -89.20934500460052,
      z: 0.16406229249920848,
    },
  },
  {
    sid: 'f5fb07930f904702a2f65595588c550e',
    id: 'f5fb07930f904702a2f65595588c550e',
    position: {
      x: -15.227002143859863,
      y: 1.5482510328292847,
      z: -8.793484687805176,
    },
    rotation: {
      x: 0.524427174948973,
      y: -33.97133373848726,
      z: 0.4621282108390348,
    },
  },
  {
    sid: 'f4b9e5b8ab4742169b204686fcb63660',
    id: 'f4b9e5b8ab4742169b204686fcb63660',
    position: {
      x: -13.78378963470459,
      y: 1.5554074048995972,
      z: -8.782593727111816,
    },
    rotation: {
      x: 0.827200516305316,
      y: -60.344117058899464,
      z: 0.24895545020251866,
    },
  },
  {
    sid: 'd1f63324351f4c3f8a97df8db304589e',
    id: 'd1f63324351f4c3f8a97df8db304589e',
    position: {
      x: -13.71999454498291,
      y: 1.557403802871704,
      z: -9.686827659606934,
    },
    rotation: {
      x: 0.7525154763443423,
      y: -92.59246605729813,
      z: 0.34803415057241854,
    },
  },
  {
    sid: '4e7114af965e492e99e55552b9a443f7',
    id: '4e7114af965e492e99e55552b9a443f7',
    position: {
      x: -12.44126033782959,
      y: 1.553590178489685,
      z: -8.222637176513672,
    },
    rotation: {
      x: 1.0137111703484,
      y: -44.37990400145887,
      z: -0.3803741580553716,
    },
  },
  {
    sid: '9a799fc8df6e4c8e88a2ce873d45f2e4',
    id: '9a799fc8df6e4c8e88a2ce873d45f2e4',
    position: {
      x: -13.51688289642334,
      y: 1.5473359823226929,
      z: -7.785862445831299,
    },
    rotation: {
      x: 0.8807592130336693,
      y: -50.566828248568314,
      z: -0.28036220658818894,
    },
  },
  {
    sid: '1867c75806914906a214acfadd659d8c',
    id: '1867c75806914906a214acfadd659d8c',
    position: {
      x: -13.666444778442383,
      y: 1.5492360591888428,
      z: -6.764477729797363,
    },
    rotation: {
      x: 0.5365121706549291,
      y: -33.520061300015215,
      z: -0.3731887911550214,
    },
  },
  {
    sid: 'c34af4b41a6e43e0b6e72981f8fb37d8',
    id: 'c34af4b41a6e43e0b6e72981f8fb37d8',
    position: {
      x: -13.462942123413086,
      y: 1.5462498664855957,
      z: -5.522815704345703,
    },
    rotation: {
      x: 0.07165184409164345,
      y: 44.12416547887867,
      z: 0.06428427732056913,
    },
  },
  {
    sid: '60ffc6b3adfe4d8ca192213027b4177d',
    id: '60ffc6b3adfe4d8ca192213027b4177d',
    position: {
      x: -13.570842742919922,
      y: 1.5508126020431519,
      z: -4.654103755950928,
    },
    rotation: {
      x: 0.22121319945091786,
      y: 10.653810923019192,
      z: 0.03391200869832442,
    },
  },
  {
    sid: '96e4685ab03643469d4fc4bc34096c44',
    id: '96e4685ab03643469d4fc4bc34096c44',
    position: {
      x: -14.236979484558105,
      y: 1.5508623123168945,
      z: -3.8084912300109863,
    },
    rotation: {
      x: 0.48031425909452863,
      y: -14.243243423168181,
      z: -0.22841284251244082,
    },
  },
  {
    sid: '3a7f0c2f244c4b84a1799a2c6dc1995c',
    id: '3a7f0c2f244c4b84a1799a2c6dc1995c',
    position: {
      x: -15.159456253051758,
      y: 1.5532605648040771,
      z: -3.976219415664673,
    },
    rotation: {
      x: 0.9017878922553185,
      y: -25.44741993995533,
      z: -0.015448736058968422,
    },
  },
  {
    sid: 'b8a7529d4cb94e9880605dbc5b535bf1',
    id: 'b8a7529d4cb94e9880605dbc5b535bf1',
    position: {
      x: -16.177127838134766,
      y: 1.555076003074646,
      z: -3.8789761066436768,
    },
    rotation: {
      x: 0.9988182770865296,
      y: -17.492342950673915,
      z: 0.2550004911565642,
    },
  },
  {
    sid: '254e5ce3a28f4bc88802e007ad4c0607',
    id: '254e5ce3a28f4bc88802e007ad4c0607',
    position: {
      x: -16.36049461364746,
      y: 1.5543992519378662,
      z: -5.3387603759765625,
    },
    rotation: {
      x: 0.5151473387800044,
      y: -59.189764005645095,
      z: 0.2461932658730927,
    },
  },
  {
    sid: 'ef08235f5be34ac0b502371a0f0afc15',
    id: 'ef08235f5be34ac0b502371a0f0afc15',
    position: {
      x: -16.41434669494629,
      y: 1.5511868000030518,
      z: -6.431675434112549,
    },
    rotation: {
      x: 0.5749763430001142,
      y: -58.500224295281825,
      z: 0.13921747109731059,
    },
  },
  {
    sid: '59fcd8019b4540f881b1f78a6b73c2e6',
    id: '59fcd8019b4540f881b1f78a6b73c2e6',
    position: {
      x: -16.27680015563965,
      y: 1.55121648311615,
      z: -7.729312419891357,
    },
    rotation: {
      x: 0.44066815721169006,
      y: -46.42824462587551,
      z: 0.6553042314133064,
    },
  },
  {
    sid: '8ac08d3c909a4485b363799e7b50b4ab',
    id: '8ac08d3c909a4485b363799e7b50b4ab',
    position: {
      x: -12.596994400024414,
      y: 1.550328016281128,
      z: -5.078983783721924,
    },
    rotation: {
      x: 0.34524868518347124,
      y: -54.81172823547155,
      z: -0.20195580038429667,
    },
  },
  {
    sid: 'd124bee4790f46658490cbb818889b16',
    id: 'd124bee4790f46658490cbb818889b16',
    position: {
      x: -11.671360969543457,
      y: 1.5497956275939941,
      z: -5.076787948608398,
    },
    rotation: {
      x: -0.018847147229433862,
      y: -33.40184562186277,
      z: -0.7843603861385897,
    },
  },
  {
    sid: '1a2705aeedf4400aaf084e4b02c84fd2',
    id: '1a2705aeedf4400aaf084e4b02c84fd2',
    position: {
      x: -11.337166786193848,
      y: 1.5471713542938232,
      z: -5.91182804107666,
    },
    rotation: {
      x: 0.2618342108798979,
      y: 2.245925133426922,
      z: -0.5827904912748998,
    },
  },
  {
    sid: '22094676aaf24f5f8d6cb22c7511c364',
    id: '22094676aaf24f5f8d6cb22c7511c364',
    position: {
      x: -11.07445240020752,
      y: 1.5460206270217896,
      z: -6.970897674560547,
    },
    rotation: {
      x: 0.701942863876115,
      y: -18.24962858159641,
      z: 0.05365290024577018,
    },
  },
  {
    sid: 'c520a993b1ba49ad85a1ac311d2fe64e',
    id: 'c520a993b1ba49ad85a1ac311d2fe64e',
    position: {
      x: -8.57508373260498,
      y: 1.546440601348877,
      z: -6.560535907745361,
    },
    rotation: {
      x: 0.5936890864634481,
      y: -44.242887177720476,
      z: -0.2289898359457995,
    },
  },
  {
    sid: '07e700fc1fbc4c1fb6007abfc711b9f2',
    id: '07e700fc1fbc4c1fb6007abfc711b9f2',
    position: {
      x: -8.238448143005371,
      y: 1.548214077949524,
      z: -5.626720428466797,
    },
    rotation: {
      x: -0.2976551770347896,
      y: 46.69014278269318,
      z: 0.18107115808864707,
    },
  },
  {
    sid: 'ef0728600b4244bd8230bb841f2842db',
    id: 'ef0728600b4244bd8230bb841f2842db',
    position: {
      x: -7.812868595123291,
      y: 1.5500755310058594,
      z: -4.765853404998779,
    },
    rotation: {
      x: -0.1945929432470007,
      y: -22.885061416431718,
      z: 0.25742038687233987,
    },
  },
  {
    sid: '935b94d2601249fe979aa1638095b6ee',
    id: '935b94d2601249fe979aa1638095b6ee',
    position: {
      x: -6.924964427947998,
      y: 1.549361228942871,
      z: -4.752269744873047,
    },
    rotation: {
      x: -0.061783282694977106,
      y: 97.05128146221415,
      z: 0.834736095561782,
    },
  },
  {
    sid: '1bd8e4008f00436c915f21f96db852f7',
    id: '1bd8e4008f00436c915f21f96db852f7',
    position: {
      x: -5.7042670249938965,
      y: 1.5515555143356323,
      z: -4.736603260040283,
    },
    rotation: {
      x: 0.07512083980071355,
      y: 119.49479002538419,
      z: 0.5103806623605955,
    },
  },
  {
    sid: 'f2f23ddcfeae4b8bb352ebed4e3dccb1',
    id: 'f2f23ddcfeae4b8bb352ebed4e3dccb1',
    position: {
      x: -5.7271318435668945,
      y: 1.548563003540039,
      z: -5.8068647384643555,
    },
    rotation: {
      x: -0.9201374059087929,
      y: 130.01455982803517,
      z: 0.33201052137936915,
    },
  },
  {
    sid: 'd35878f0eb724b6d80a444cf32aaa1a4',
    id: 'd35878f0eb724b6d80a444cf32aaa1a4',
    position: {
      x: -4.9107537269592285,
      y: 1.549804925918579,
      z: -6.7271728515625,
    },
    rotation: {
      x: -0.7220528212921615,
      y: 84.7410167573901,
      z: 0.6867488991763161,
    },
  },
  {
    sid: '79fbba2a8bb24cfea5cb8422a11c076d',
    id: '79fbba2a8bb24cfea5cb8422a11c076d',
    position: {
      x: -3.8201780319213867,
      y: 1.5576324462890625,
      z: -7.272469520568848,
    },
    rotation: {
      x: 0.18018250292913549,
      y: 80.69404261987941,
      z: 0.1856130482013505,
    },
  },
  {
    sid: '028924aefa694c578156a01460995d56',
    id: '028924aefa694c578156a01460995d56',
    position: {
      x: -5.520567417144775,
      y: 1.5443260669708252,
      z: -8.222631454467773,
    },
    rotation: {
      x: -0.07103571462456026,
      y: 156.94151487610313,
      z: -0.2835832853892553,
    },
  },
  {
    sid: '321ece55254941729ae473ed8fc8edcb',
    id: '321ece55254941729ae473ed8fc8edcb',
    position: {
      x: -4.198940753936768,
      y: 1.550333023071289,
      z: -4.356204032897949,
    },
    rotation: {
      x: -0.24282216518226635,
      y: -71.3454341422357,
      z: -0.5351766038080623,
    },
  },
  {
    sid: 'e668d762ed1640b6b2dbbcac9552ed28',
    id: 'e668d762ed1640b6b2dbbcac9552ed28',
    position: {
      x: -2.943186044692993,
      y: 1.5481562614440918,
      z: -4.348824501037598,
    },
    rotation: {
      x: 0.08880452586994445,
      y: -65.53370786953154,
      z: -0.20356181578699303,
    },
  },
  {
    sid: '19c2c435ec534109afc6a85b97022dfb',
    id: '19c2c435ec534109afc6a85b97022dfb',
    position: {
      x: -2.687133312225342,
      y: 1.548877239227295,
      z: -2.933227062225342,
    },
    rotation: {
      x: 0.26018580546664116,
      y: 5.638983289678835,
      z: -0.34064264612026046,
    },
  },
  {
    sid: 'c4878857ddd949e6ba182d0f1f35b76b',
    id: 'c4878857ddd949e6ba182d0f1f35b76b',
    position: {
      x: -2.7057976722717285,
      y: 1.5570405721664429,
      z: -1.8472620248794556,
    },
    rotation: {
      x: 0.4988189617387018,
      y: 8.773877923121127,
      z: -0.33690490797585343,
    },
  },
  {
    sid: '252dc2c1e0a544baac4737d863bab8fb',
    id: '252dc2c1e0a544baac4737d863bab8fb',
    position: {
      x: -2.960423707962036,
      y: 1.5579160451889038,
      z: -0.7883679866790771,
    },
    rotation: {
      x: 0.9054729089350441,
      y: 14.22619897585305,
      z: 0.026282199997442832,
    },
  },
  {
    sid: 'e55cf8af91364770a22d11762400597b',
    id: 'e55cf8af91364770a22d11762400597b',
    position: {
      x: -4.1938066482543945,
      y: 1.552181601524353,
      z: -0.7286506295204163,
    },
    rotation: {
      x: 0.5503665098714436,
      y: 7.840857898230511,
      z: -0.37079472741867436,
    },
  },
  {
    sid: '9499bd5432534d2ca26a1cba3ed3b885',
    id: '9499bd5432534d2ca26a1cba3ed3b885',
    position: {
      x: -16.047584533691406,
      y: 1.55631422996521,
      z: -2.882371425628662,
    },
    rotation: {
      x: 0.3310483763371255,
      y: -36.49534962483597,
      z: 0.452304309746008,
    },
  },
  {
    sid: '851da297f83c4383b47fac5d827dde39',
    id: '851da297f83c4383b47fac5d827dde39',
    position: {
      x: -16.009477615356445,
      y: 1.559692144393921,
      z: -2.2555477619171143,
    },
    rotation: {
      x: 0.8447598305869528,
      y: -33.66277110024457,
      z: -0.5611113227872837,
    },
  },
  {
    sid: '2c183fa100d04c6b832d04078698fcf0',
    id: '2c183fa100d04c6b832d04078698fcf0',
    position: {
      x: -15.848864555358887,
      y: 1.5536972284317017,
      z: -1.1164674758911133,
    },
    rotation: {
      x: 0.34280037525299706,
      y: -10.308471013878684,
      z: -0.21197535060936407,
    },
  },
  {
    sid: '64aecb6156e1407a82ff636d699c1922',
    id: '64aecb6156e1407a82ff636d699c1922',
    position: {
      x: -14.915244102478027,
      y: 1.5542244911193848,
      z: -1.1288801431655884,
    },
    rotation: {
      x: 0.03203972739738338,
      y: -8.48873999397544,
      z: -0.7861472997353927,
    },
  },
  {
    sid: '9371f4058a2a4d2a8024393088641c53',
    id: '9371f4058a2a4d2a8024393088641c53',
    position: {
      x: -14.177800178527832,
      y: 1.5468906164169312,
      z: -1.0708435773849487,
    },
    rotation: {
      x: 0.823853532559223,
      y: 8.961595178902552,
      z: -0.5461425982029692,
    },
  },
  {
    sid: '0d065eee4df547ddb17274f393337ff0',
    id: '0d065eee4df547ddb17274f393337ff0',
    position: {
      x: -14.013869285583496,
      y: 1.5511976480484009,
      z: -2.3681724071502686,
    },
    rotation: {
      x: 0.46868173387921325,
      y: 4.980329573535948,
      z: -0.4605960940676209,
    },
  },
  {
    sid: '23a29dcfb90242ae89d36a3deac1d9a0',
    id: '23a29dcfb90242ae89d36a3deac1d9a0',
    position: {
      x: -14.018436431884766,
      y: 1.5538824796676636,
      z: -3.103696346282959,
    },
    rotation: {
      x: 0.44596021707603406,
      y: 4.324253008014737,
      z: -0.5390681712596775,
    },
  },
  {
    sid: '3cced256f4ba493481572fb0ab92dc01',
    id: '3cced256f4ba493481572fb0ab92dc01',
    position: {
      x: -13.168412208557129,
      y: 1.5487117767333984,
      z: -0.9775829315185547,
    },
    rotation: {
      x: 0.92431653648502,
      y: -38.38509237085861,
      z: -0.11112807130425774,
    },
  },
  {
    sid: 'cc0f0217ea844e3abca97ac907ba9bd5',
    id: 'cc0f0217ea844e3abca97ac907ba9bd5',
    position: {
      x: -12.402326583862305,
      y: 1.5525052547454834,
      z: -1.1863867044448853,
    },
    rotation: {
      x: 0.11901327285778869,
      y: -29.693749429802818,
      z: -0.8345109055521633,
    },
  },
  {
    sid: 'fe2172b855404426b9a0a9118f8bec70',
    id: 'fe2172b855404426b9a0a9118f8bec70',
    position: {
      x: -11.154141426086426,
      y: 1.5517710447311401,
      z: -1.028293490409851,
    },
    rotation: {
      x: 0.7808502384214489,
      y: 20.732081226783563,
      z: -0.7541834878380868,
    },
  },
  {
    sid: 'd2cbdc397e4a4438b298369f891c6494',
    id: 'd2cbdc397e4a4438b298369f891c6494',
    position: {
      x: -10.842218399047852,
      y: 1.5496635437011719,
      z: -2.3109841346740723,
    },
    rotation: {
      x: 0.5340557093684203,
      y: 5.024013087172793,
      z: -0.666345324560184,
    },
  },
  {
    sid: '50c2ce05baf84906a37726018837fa56',
    id: '50c2ce05baf84906a37726018837fa56',
    position: {
      x: -11.027518272399902,
      y: 1.5496954917907715,
      z: -3.337097644805908,
    },
    rotation: {
      x: -0.34378645232005095,
      y: 2.281174037640301,
      z: -0.27064648956204923,
    },
  },
  {
    sid: '0d5b4b5265a748c4a3c23fac316edd3c',
    id: '0d5b4b5265a748c4a3c23fac316edd3c',
    position: {
      x: -11.190224647521973,
      y: 1.5450077056884766,
      z: -4.411144256591797,
    },
    rotation: {
      x: 0.052775284932929906,
      y: -0.6777080393286101,
      z: -0.5581449522487587,
    },
  },
  {
    sid: 'cd8769c62e244cbc9845d6c661c0ca0f',
    id: 'cd8769c62e244cbc9845d6c661c0ca0f',
    position: {
      x: -10.18874454498291,
      y: 1.5508736371994019,
      z: -3.6253418922424316,
    },
    rotation: {
      x: 0.04655466163207132,
      y: -9.07432129584931,
      z: -0.3750283229196135,
    },
  },
  {
    sid: 'c27bc2b96ef4438a9dca6ed206af76e9',
    id: 'c27bc2b96ef4438a9dca6ed206af76e9',
    position: {
      x: -9.515080451965332,
      y: 1.5486124753952026,
      z: -3.206639051437378,
    },
    rotation: {
      x: 0.7348748517454962,
      y: -3.743617141358727,
      z: -0.0801884405890554,
    },
  },
  {
    sid: 'c862ac15c8be4da4885efdacb78994dc',
    id: 'c862ac15c8be4da4885efdacb78994dc',
    position: {
      x: -9.015551567077637,
      y: 1.550395131111145,
      z: -3.78631854057312,
    },
    rotation: {
      x: 0.42588372085542503,
      y: 37.39923827487254,
      z: 0.30742588346448163,
    },
  },
  {
    sid: 'd86a8f46930845558bc1ce271cf81036',
    id: 'd86a8f46930845558bc1ce271cf81036',
    position: {
      x: -8.56076717376709,
      y: 1.5522890090942383,
      z: -4.425879001617432,
    },
    rotation: {
      x: 0.361791346764771,
      y: 32.769345846794906,
      z: -0.33531857304621693,
    },
  },
  {
    sid: 'dda19a0731124edab9444e1c87e99aee',
    id: 'dda19a0731124edab9444e1c87e99aee',
    position: {
      x: -9.382040023803711,
      y: 1.546871542930603,
      z: -2.2260842323303223,
    },
    rotation: {
      x: 0.2539232560437555,
      y: 40.029275710924026,
      z: 0.4319857960715473,
    },
  },
  {
    sid: 'e661595f4c6841c38b11135d1b3d0066',
    id: 'e661595f4c6841c38b11135d1b3d0066',
    position: {
      x: -5.972140312194824,
      y: 1.549729347229004,
      z: -3.559250831604004,
    },
    rotation: {
      x: -0.5726660580549934,
      y: -37.58137128982161,
      z: -0.5259766315249012,
    },
  },
  {
    sid: 'bf5328f722dd4c07871a1c28d65a6acf',
    id: 'bf5328f722dd4c07871a1c28d65a6acf',
    position: {
      x: -5.941184043884277,
      y: 1.5531288385391235,
      z: -2.3509864807128906,
    },
    rotation: {
      x: 0.4685474984296353,
      y: -46.29094980677589,
      z: -0.8540521436692771,
    },
  },
  {
    sid: 'ffb41df0d2984d82a6db24584bf837e2',
    id: 'ffb41df0d2984d82a6db24584bf837e2',
    position: {
      x: -5.976471424102783,
      y: 1.5535672903060913,
      z: -1.0036224126815796,
    },
    rotation: {
      x: 0.45306346770273576,
      y: -72.23172033885335,
      z: -0.5394946052305596,
    },
  },
  {
    sid: '60814e5f48854d05822c3da364bbac85',
    id: '60814e5f48854d05822c3da364bbac85',
    position: {
      x: -2.706573963165283,
      y: 1.544687032699585,
      z: -4.897237300872803,
    },
    rotation: {
      x: 0.42123796314155054,
      y: -101.88996245408983,
      z: 0.364323252094451,
    },
  },
  {
    sid: '078c47ec08334c28a02b286f31dedaf6',
    id: '078c47ec08334c28a02b286f31dedaf6',
    position: {
      x: -2.3377764225006104,
      y: 1.5418418645858765,
      z: -5.715295791625977,
    },
    rotation: {
      x: -0.21925960160215854,
      y: -99.30491061930852,
      z: 0.763164637465307,
    },
  },
  {
    sid: 'ffcf97eb63d845a690ae5a1a07e00377',
    id: 'ffcf97eb63d845a690ae5a1a07e00377',
    position: {
      x: -10.392376899719238,
      y: 1.5538380146026611,
      z: -9.903807640075684,
    },
    rotation: {
      x: -0.3623244060855755,
      y: -89.43211225036804,
      z: 0.7228921191129203,
    },
  },
  {
    sid: '8fa54f162f4a466a8f8beb5e34082f42',
    id: '8fa54f162f4a466a8f8beb5e34082f42',
    position: {
      x: 1.1881693601608276,
      y: 1.5600414276123047,
      z: -3.233175039291382,
    },
    rotation: {
      x: -0.5946230034868856,
      y: -16.461836539212726,
      z: -0.14834189869413555,
    },
  },
  {
    sid: '5ac7ca84b7cc46b997c71521cbf8743b',
    id: '5ac7ca84b7cc46b997c71521cbf8743b',
    position: {
      x: -0.017763927578926086,
      y: 1.5655115842819214,
      z: 0.44273531436920166,
    },
    rotation: {
      x: -0.23055727464828543,
      y: 21.444637424202213,
      z: 0.1195957487462145,
    },
  },
  {
    sid: '245611a5dc8a48d8a18b29c0530903c7',
    id: '245611a5dc8a48d8a18b29c0530903c7',
    position: {
      x: 1.1346737146377563,
      y: 1.5606461763381958,
      z: 0.32524049282073975,
    },
    rotation: {
      x: -0.2223218510812202,
      y: 75.91356172713367,
      z: 0.4637550979934738,
    },
  },
  {
    sid: '2e5ff3bfcc9d40a8bdba7a4b339f6b16',
    id: '2e5ff3bfcc9d40a8bdba7a4b339f6b16',
    position: {
      x: 1.1586251258850098,
      y: 1.5609362125396729,
      z: -0.7276378870010376,
    },
    rotation: {
      x: -0.8837785853424359,
      y: -133.47488316130435,
      z: 0.3611651664757176,
    },
  },
  {
    sid: '242b15b3267b452981bb6b3058fefcab',
    id: '242b15b3267b452981bb6b3058fefcab',
    position: {
      x: 0.478885680437088,
      y: 1.564491629600525,
      z: -2.073662042617798,
    },
    rotation: {
      x: -0.1673989252492365,
      y: -11.401513710564663,
      z: 0.9498156559828145,
    },
  },
  {
    sid: '09d27d03d0f84420b5d5817d8b4fb695',
    id: '09d27d03d0f84420b5d5817d8b4fb695',
    position: {
      x: 1.2388510704040527,
      y: 1.5582281351089478,
      z: -2.0564374923706055,
    },
    rotation: {
      x: -0.5835741007676892,
      y: -22.169855387005512,
      z: 0.1259795763612301,
    },
  },
  {
    sid: 'd92af1237324495db5556f150eee8f29',
    id: 'd92af1237324495db5556f150eee8f29',
    position: {
      x: -15.851529121398926,
      y: 1.5570552349090576,
      z: 0.3500133156776428,
    },
    rotation: {
      x: 0.12100749458367771,
      y: 68.73743936174942,
      z: -0.10040574496414438,
    },
  },
  {
    sid: '821619aeb3fa4737a1f39f186d8803ab',
    id: '821619aeb3fa4737a1f39f186d8803ab',
    position: {
      x: -15.844014167785645,
      y: 1.558960199356079,
      z: 2.437809944152832,
    },
    rotation: {
      x: 0.5893978070254027,
      y: 62.472814419280105,
      z: 0.43993552902788424,
    },
  },
  {
    sid: '20143234bab54ce8ae39f170bbaa515a',
    id: '20143234bab54ce8ae39f170bbaa515a',
    position: {
      x: -15.850702285766602,
      y: 1.5566434860229492,
      z: 4.029383182525635,
    },
    rotation: {
      x: 0.7422580099989855,
      y: 43.85230478623679,
      z: 0.6957519970751088,
    },
  },
  {
    sid: '68c57880ce92465e8a4458d65c1a00cb',
    id: '68c57880ce92465e8a4458d65c1a00cb',
    position: {
      x: -12.776535987854004,
      y: 1.552678108215332,
      z: 4.052151679992676,
    },
    rotation: {
      x: 0.35217410028822294,
      y: 6.797277813094829,
      z: 0.48006440140839474,
    },
  },
  {
    sid: '6056dd185e934876911fcc1f1aa61e6d',
    id: '6056dd185e934876911fcc1f1aa61e6d',
    position: {
      x: -12.64094352722168,
      y: 1.5500432252883911,
      z: 1.390337347984314,
    },
    rotation: {
      x: 0.4111139178231522,
      y: -16.36677356687058,
      z: 0.35878308731154185,
    },
  },
  {
    sid: '4854d8bdabd3415e8bb80e5f23d601df',
    id: '4854d8bdabd3415e8bb80e5f23d601df',
    position: {
      x: -12.510954856872559,
      y: 1.5458024740219116,
      z: 0.008978220634162426,
    },
    rotation: {
      x: -0.018405827248857417,
      y: -16.544351935314367,
      z: 0.518237823678105,
    },
  },
  {
    sid: 'c31d4e690dd842f492b35f125648f721',
    id: 'c31d4e690dd842f492b35f125648f721',
    position: {
      x: -8.811504364013672,
      y: 1.5483372211456299,
      z: -1.102289080619812,
    },
    rotation: {
      x: 0.20149181933345123,
      y: -128.72268291025586,
      z: -0.31866292000068386,
    },
  },
  {
    sid: '2c44a03bf1834964bc33ba0c04866fdd',
    id: '2c44a03bf1834964bc33ba0c04866fdd',
    position: {
      x: -10.043815612792969,
      y: 1.5466763973236084,
      z: -1.2887393236160278,
    },
    rotation: {
      x: 0.7146087826773431,
      y: -81.78835240266264,
      z: 0.2728626341564977,
    },
  },
  {
    sid: '9ec310896e9e473b870589e7a1bce5e3',
    id: '9ec310896e9e473b870589e7a1bce5e3',
    position: {
      x: -6.885660648345947,
      y: 1.5545657873153687,
      z: -1.003724217414856,
    },
    rotation: {
      x: 0.2253280479584166,
      y: -130.2321498657082,
      z: -0.5562490818775898,
    },
  },
  {
    sid: 'a96ee79fe8444c62a7b2604fcee95390',
    id: 'a96ee79fe8444c62a7b2604fcee95390',
    position: {
      x: -6.867376327514648,
      y: 1.5488392114639282,
      z: 0.5484333038330078,
    },
    rotation: {
      x: -2.442898150825954,
      y: -131.35524288892898,
      z: -1.813624781101951,
    },
  },
  {
    sid: 'c1e725c0ebe64cd5833bdd3252a8293f',
    id: 'c1e725c0ebe64cd5833bdd3252a8293f',
    position: {
      x: -6.795849800109863,
      y: 1.5066180229187012,
      z: 2.5828535556793213,
    },
    rotation: {
      x: -1.3493417558674095,
      y: -135.36281966864,
      z: 0.7113501251069113,
    },
  },
  {
    sid: 'a7af6068f1744712bc151ce4be109727',
    id: 'a7af6068f1744712bc151ce4be109727',
    position: {
      x: -9.673978805541992,
      y: 1.5602487325668335,
      z: 3.8886585235595703,
    },
    rotation: {
      x: -0.2580117408651953,
      y: -177.98883684168052,
      z: -0.8864756918083279,
    },
  },
  {
    sid: '1d3aa15afe6f4572a187f4c069f1226f',
    id: '1d3aa15afe6f4572a187f4c069f1226f',
    position: {
      x: -15.16766357421875,
      y: 1.5538500547409058,
      z: 4.822051525115967,
    },
    rotation: {
      x: -0.25240474912033056,
      y: -179.46072588043117,
      z: -0.6144416075660045,
    },
  },
  {
    sid: '7b7f2e1b55764ba5a7d171d6016d8316',
    id: '7b7f2e1b55764ba5a7d171d6016d8316',
    position: {
      x: -9.984573364257812,
      y: 1.5571479797363281,
      z: 2.0382752418518066,
    },
    rotation: {
      x: -0.06967254236557788,
      y: -172.81712658639313,
      z: -0.44544689576905927,
    },
  },
  {
    sid: 'd5e72b5b9bc34010b4119fc54b526837',
    id: 'd5e72b5b9bc34010b4119fc54b526837',
    position: {
      x: -4.091342926025391,
      y: 1.4187376499176025,
      z: 3.704850673675537,
    },
    rotation: {
      x: -1.1460868730186593,
      y: -165.23683882959133,
      z: 1.6813462115389874,
    },
  },
  {
    sid: '38a0b601bc29433185567fcb274a5b0d',
    id: '38a0b601bc29433185567fcb274a5b0d',
    position: {
      x: -0.27831634879112244,
      y: 1.324610948562622,
      z: 3.5606775283813477,
    },
    rotation: {
      x: 1.11523630283782,
      y: 101.23532102635546,
      z: 0.23765684535965118,
    },
  },
  {
    sid: '196b99a9a6d04b0d8839ff68c4c1fde5',
    id: '196b99a9a6d04b0d8839ff68c4c1fde5',
    position: {
      x: -1.8013790845870972,
      y: 1.3685811758041382,
      z: 2.161848783493042,
    },
    rotation: {
      x: -1.3189621681394503,
      y: -81.6694950735153,
      z: 0.5011853776973527,
    },
  },
  {
    sid: '48674d85b0c142d8af2991489a5504d2',
    id: '48674d85b0c142d8af2991489a5504d2',
    position: {
      x: -6.767837047576904,
      y: 1.500032901763916,
      z: 4.805556774139404,
    },
    rotation: {
      x: -1.3596782003967038,
      y: -93.67232148848788,
      z: -0.17513756866750127,
    },
  },
  {
    sid: '9b2a24319ecd42ddb41b2208a20aca3d',
    id: '9b2a24319ecd42ddb41b2208a20aca3d',
    position: {
      x: -14.105002403259277,
      y: 1.549257516860962,
      z: 2.926969051361084,
    },
    rotation: {
      x: 0.28407718833284673,
      y: -125.29714610698008,
      z: 0.4971520645503698,
    },
  },
  {
    sid: 'c9a53d5200f841ae8e3debf9b591715f',
    id: 'c9a53d5200f841ae8e3debf9b591715f',
    position: {
      x: -16.456707000732422,
      y: 1.547905445098877,
      z: -8.762197494506836,
    },
    rotation: {
      x: -0.19918435729703396,
      y: -113.30943747220324,
      z: 0.522828254558517,
    },
  },
  {
    sid: '7105efe31f084747a48c0db740b0ef50',
    id: '7105efe31f084747a48c0db740b0ef50',
    position: {
      x: -16.349533081054688,
      y: 1.5555787086486816,
      z: -6.2757039070129395,
    },
    rotation: {
      x: -0.40996461532326134,
      y: -74.45734614291553,
      z: -0.2725354717366199,
    },
  },
  {
    sid: '1c15aa9a5a07492d8a2e1d5e98dc224a',
    id: '1c15aa9a5a07492d8a2e1d5e98dc224a',
    position: {
      x: -16.238821029663086,
      y: 1.54508376121521,
      z: -11.217727661132812,
    },
    rotation: {
      x: -0.2535419554130836,
      y: -109.24977393973643,
      z: 0.2973338102036069,
    },
  },
  {
    sid: '4c1ed21316ea4a44825f1e5a347af4d8',
    id: '4c1ed21316ea4a44825f1e5a347af4d8',
    position: {
      x: -13.980656623840332,
      y: 1.551768183708191,
      z: -11.357783317565918,
    },
    rotation: {
      x: 0.13171652693192681,
      y: -155.20045461165827,
      z: -0.3987126097569562,
    },
  },
  {
    sid: 'f8ab0d23d37946d38a9bf4c649db6e61',
    id: 'f8ab0d23d37946d38a9bf4c649db6e61',
    position: {
      x: -5.338374137878418,
      y: 1.5455188751220703,
      z: -6.292323112487793,
    },
    rotation: {
      x: -0.09002853963537409,
      y: -54.13058838714721,
      z: -0.0378443952381506,
    },
  },
  {
    sid: 'c6623b55ca594c14acb9659c8ff3eaf8',
    id: 'c6623b55ca594c14acb9659c8ff3eaf8',
    position: {
      x: -8.61366081237793,
      y: 1.542924165725708,
      z: -7.659670352935791,
    },
    rotation: {
      x: -0.5638181600423422,
      y: -70.41411918660549,
      z: -0.03542668444505142,
    },
  },
  {
    sid: '666925174b874d69b9e9ab3098af32eb',
    id: '666925174b874d69b9e9ab3098af32eb',
    position: {
      x: -10.855799674987793,
      y: 1.5474703311920166,
      z: -1.1509746313095093,
    },
    rotation: {
      x: -0.3528298149580684,
      y: -42.483930544923616,
      z: -0.22357923113843706,
    },
  },
  {
    sid: 'b31639510b7a48d7949210d948599d23',
    id: 'b31639510b7a48d7949210d948599d23',
    position: {
      x: -12.66872501373291,
      y: 1.5491405725479126,
      z: -0.8386479020118713,
    },
    rotation: {
      x: 0.23607815722243755,
      y: -33.423162904731996,
      z: 0.4167868085643827,
    },
  },
  {
    sid: 'b5042c5ac79f48c4ba889b83186deb76',
    id: 'b5042c5ac79f48c4ba889b83186deb76',
    position: {
      x: -2.7910730838775635,
      y: 1.5589094161987305,
      z: -0.7441774010658264,
    },
    rotation: {
      x: -0.4106456180375779,
      y: 18.64316345053352,
      z: -0.17612841423344383,
    },
  },
  {
    sid: '2e16d43a2cd84ad796abb5f3661c6de2',
    id: '2e16d43a2cd84ad796abb5f3661c6de2',
    position: {
      x: -2.578136920928955,
      y: 1.5506242513656616,
      z: -6.849626064300537,
    },
    rotation: {
      x: -0.20726701208307952,
      y: 169.73085036967288,
      z: 0.20188248892197055,
    },
  },
  {
    sid: '80d92769fc7442a6814720e86b5b438d',
    id: '80d92769fc7442a6814720e86b5b438d',
    position: {
      x: -8.545918464660645,
      y: 1.5462191104888916,
      z: -9.204456329345703,
    },
    rotation: {
      x: -0.605998229181415,
      y: -159.20980044203466,
      z: 0.5423955999186505,
    },
  },
  {
    sid: '40536121d43b4bfb85c9889160b8c04e',
    id: '40536121d43b4bfb85c9889160b8c04e',
    position: {
      x: -16.361085891723633,
      y: 1.545837163925171,
      z: -8.713232040405273,
    },
    rotation: {
      x: -0.3507221452320367,
      y: -57.06298649237949,
      z: 0.21337408608185582,
    },
  },
  {
    sid: '7d51d866b6d54dc4a2889a1e7fa9412e',
    id: '7d51d866b6d54dc4a2889a1e7fa9412e',
    position: {
      x: -16.375362396240234,
      y: 1.5504661798477173,
      z: -6.569584369659424,
    },
    rotation: {
      x: -0.06634284956028165,
      y: -96.01862787462318,
      z: 0.09115039272802193,
    },
  },
  {
    sid: '4bd43f0fa5cd4c65a488d7c0e2cf4437',
    id: '4bd43f0fa5cd4c65a488d7c0e2cf4437',
    position: {
      x: -5.208761215209961,
      y: 1.5345643758773804,
      z: -11.299210548400879,
    },
    rotation: {
      x: -0.42775262124247476,
      y: 81.28262732957029,
      z: -0.31608234412623015,
    },
  },
  {
    sid: 'cd594d8e73154ff9a26b233113d85863',
    id: 'cd594d8e73154ff9a26b233113d85863',
    position: {
      x: -4.233467102050781,
      y: 1.541199803352356,
      z: -11.246021270751953,
    },
    rotation: {
      x: -0.556726024082265,
      y: 69.6834940891678,
      z: 0.1346639693446674,
    },
  },
];

export default locations;
