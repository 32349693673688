import React, { memo, useMemo } from 'react';

import tagService from 'src/apis/services/tag.service';

import TagInstance from './TagInstance';

const Tags = (props) => {
  const tagMockData = useMemo(() => tagService.getTags(), []);

  return (
    <>
      {tagMockData.map((tag) => {
        return <TagInstance key={`tag-${tag.id}`} tag={tag} />;
      })}
    </>
  );
};

export default memo(Tags);
