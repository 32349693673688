import clsx from 'clsx';
// import Papa from 'papaparse';
import React, { useEffect, useMemo, useState } from 'react';

import { Box, IconButton, Typography, makeStyles } from '@material-ui/core';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';

import locationService from 'src/apis/services/location.service';
import mediaService from 'src/apis/services/media.service';
import productService from 'src/apis/services/product.service';
import useStoreContext from 'src/hooks/useStoreContext';
import resourceManager from 'src/services/resource.manager';
import { BorderLinearProgress } from 'src/styled-components/BorderLinearProgress';

const useStyles = makeStyles((theme) => ({
  buttonIconContainer: {
    position: 'absolute',
    bottom: 26,
    right: 60,
    zIndex: 100,
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  updateIconButton: {
    backgroundColor: '#ee1f3b',
    padding: 4,
    border: 'none',
    color: '#fff',
    '& .MuiSvgIcon-root': {
      width: 26,
      height: 26,
    },
    '&:hover': {
      backgroundColor: '#ee1f3b',
    },
  },
  icon: {},
  animatedIcon: {
    animation: `$rotateZ 2s linear infinite`,
  },
  '@keyframes rotateZ': {
    '0%': {
      transform: 'rotateZ(360deg)',
      //   opacity: 0,
    },
    '100%': {
      //   opacity: 1,
      transform: 'rotateZ(0deg)',
    },
  },
  progressBox: {
    position: 'relative',
    backgroundColor: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 12px',
    borderRadius: 50,
    height: 34,
    transformOrigin: 'right',
    overflow: 'hidden',
  },
  progressText: {
    marginLeft: 4,
    color: '#fff',
    width: 54,
    textAlign: 'right',
    lineHeight: 1,
  },
  '@keyframes width-on': {
    '0%': {
      width: 34,
    },
    '100%': {
      width: '100%',
    },
  },
  '@keyframes width-off': {
    '0%': {
      width: '100%',
    },
    '100%': {
      width: 34,
    },
  },
  animateWidthOn: {
    animation: `$width-on 0.75s ease-in-out`,
  },
  animateWidthOff: {
    animation: `$width-off 0.75s ease-in-out`,
  },
}));

// const getProducts = async () => {
//   const response = await fetch('./assets/CottonOn_SKU_References_Local.csv');
//   const reader = response.body.getReader();
//   const result = await reader.read();
//   const decoder = new TextDecoder('utf-8');
//   const csv = decoder.decode(result.value);
//   const results = Papa.parse(csv, { header: true });
//   const products = results.data;

//   return products;
// };

const UpdateResourceButton = (props) => {
  const classes = useStyles();
  const { currentStoreId } = useStoreContext();

  const locations = useMemo(() => locationService.getLocations(), [currentStoreId]);
  const medias = useMemo(() => mediaService.getMedias(), [currentStoreId]);
  const products = useMemo(() => productService.getProducts(), [currentStoreId]);
  const [caching, setCaching] = useState(false);
  const [urlsList, setUrlsList] = useState([]);
  const [urlsListCompleted, setUrlsListCompleted] = useState([]);
  const [cacheCompleted, setCacheCompleted] = useState(false);

  useEffect(() => {
    async function getUrls() {
      const tmpMediaUrls = medias.map((item) => item.source);
      const tmpLocationUrls = locations.map((item) => item.img);
      const tmpProductUrls = products.map((item) => item.photo);

      let tmpUrls = [...tmpMediaUrls, ...tmpLocationUrls, ...tmpProductUrls];
      const urls = await resourceManager.checkUrlsCached(currentStoreId, tmpUrls);
      setUrlsList(urls);
    }
    if (currentStoreId) {
      getUrls();
    }
  }, [currentStoreId]);

  const handleButtonClick = async () => {
    const urlsNotCache = await resourceManager.checkUrlsCached(currentStoreId, urlsList);
    setUrlsList(urlsNotCache);
    setCaching(true);
    const completed = await resourceManager.cacheAllResource(currentStoreId, urlsNotCache);
    if (completed) {
      setTimeout(() => {
        setCacheCompleted(true);
      }, 2250);
      setTimeout(() => {
        setCaching(false);
      }, 3000);
    }
  };

  useEffect(() => {
    return () => {
      setCacheCompleted(false);
      setUrlsListCompleted([]);
    };
  }, [caching]);

  useEffect(() => {
    resourceManager.subscribeEvents(handleUpdateUrlList);
    return () => {
      resourceManager.unsubscribeEvents();
    };
  }, []);

  const handleUpdateUrlList = (url) => {
    setUrlsListCompleted((prvState) => [...prvState, url]);
  };

  return (
    <Box className={classes.buttonIconContainer}>
      {!caching && (
        <IconButton
          aria-label="update-resource"
          className={classes.updateIconButton}
          onClick={handleButtonClick}
        >
          <GetAppRoundedIcon className={caching ? classes.animatedIcon : classes.icon} />
        </IconButton>
      )}
      {caching && (
        <Box
          className={clsx(
            classes.progressBox,
            classes.animateWidthOn,
            cacheCompleted && classes.animateWidthOff
          )}
        >
          <BorderLinearProgress
            variant="determinate"
            value={(urlsListCompleted?.length / urlsList?.length) * 100}
          />
          <Typography variant="caption" className={classes.progressText}>
            {urlsListCompleted?.length !== urlsList?.length
              ? `${urlsListCompleted?.length}/${urlsList?.length}`
              : 'completed'}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default UpdateResourceButton;
